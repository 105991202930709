<template>
  <view-card-template :loaded="loaded">
    
    <template #cardTitle>
      {{ !loaded ? "Loading..." : `Stats for ${statGroup.name} (${statGroup.sport.name})`}}
    </template>

    <template #cardBody>
      <div> <!-- Enclosing element is required to make the expanding table footer look right -->

        <!-- START Stat Ordering -->
        <v-row class="mx-1 my-3">
          <v-col
            cols="9"
            class="d-flex justify-center align-center"
          >
            <draggable
              :list="orderStats"
              @start="dragging = true"
              @end="dragging = false"
              class="px-3 d-flex align-center"
              style="overflow-x: auto; border: 1px solid #999;"
            >
              <span
                v-for="(stat, index) in orderStats"
                :key="index"
                class="stat-abbrev my-2 px-1 font-weight-medium"
                style="border-radius: 5%; border: 1px solid #999; margin: 0px 1px"
              >
                  {{ stat.abbreviation }}
              </span>
            </draggable>
          </v-col>
          <v-col cols="3">
            <div
              class="d-flex align-center"
              style="height: 100%;"
            >
              <v-btn
                @click="saveStatOrder"
                color="primary"
                hide-details
                block
              >
                Save Order
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- END Stat Ordering -->

        <v-divider/>

        <!-- START Table Controls -->
        <v-row class="mx-1 mt-2 mb-3">
          <v-col cols="9">
            <v-text-field
              v-model="nameSearch"
              label="Filter by Name"
              hide-details
              clearable
            />
          </v-col>
          <v-spacer/>
          <v-col cols="3">
            <div
              class="d-flex align-center pt-2"
              style="height: 100%;"
            >
              <v-btn
                @click="openCreateModal"
                color="primary"
                hide-details
                block
              >
                Create Stat
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <!-- END Table Controls -->

        <v-data-table
          :headers="headers"
          :items="statsFiltered"
          :items-per-page="-1"
          class="expanding-v-data-table"
          dense
        >

          <template v-slot:[`item.name`]="{ item }">
            <td class="font-weight-medium">
              {{ item.name }}
            </td>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <td>
              <div class="d-flex justify-center align-center">
                <!-- Edit -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="openEditModal(item)"
                      color="blue darken-2"
                      icon
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  Edit Stat
                </v-tooltip>
                <!-- Delete -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click="deleteStat(item)"
                      color="red accent-2"
                      icon
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete Stat
                </v-tooltip>
              </div>
            </td>
          </template>

          <template #footer="{ props }">
            <v-divider v-if="props.pagination.itemsLength > 0"/>
            <div class="expanding-v-data-table--footer"></div>
          </template>
        </v-data-table> 
      </div>

      <app-modal
        v-if="modal"
        :value="modal"
        :persistent="true"
        :header-text="modalHeaderText"
        width="auto"
        min-width="800px"
      >
        <template #content>
          <v-form ref="form">
            <v-row
              class="mt-3"
              no-gutters
            >
              <!-- Name -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-text-field
                    v-model="modalStat.name"
                    label="Name"
                    :rules="[
                      (input) => { return (input != null && input.length > 0) || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
              <!-- Abbreviation -->
              <v-col cols="6">
                <div class="mx-2">
                  <v-text-field
                    v-model="modalStat.abbreviation"
                    label="Abbreviation"
                    :rules="[
                      (input) => { return (input != null && input.length > 0) || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <!-- Mapping -->
              <v-col>
                <div class="mx-2">
                  <v-text-field
                    v-model="modalStat.mapping"
                    label="Mapping"
                    :rules="[
                      (input) => { return (input != null && input.length > 0) || 'Required'}
                    ]"
                    clearable
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template #action>
          <v-spacer></v-spacer>
          <v-btn
            @click="cancelModal"
            depressed
          >
            Cancel
          </v-btn>
          <v-btn
            @click="submitStat()"
            :loading="submitStatLoading"
            class="ml-2"
            color="primary"
            depressed
          >
            Submit
          </v-btn>
        </template>
      </app-modal>

    </template>
  </view-card-template>
</template>

<script>
import AppModal from "@/components/app/AppModal"
import ViewCardTemplate from '@/components/templates/ViewCardTemplate.vue'

import Controllers from "@/data/controllers"
import GlobalSports from "@/data/GlobalSports"

import draggable from "vuedraggable";

export default {
  name: "StatManagement",

  components: {
    AppModal,
    draggable,
    ViewCardTemplate,
  },

  props: {
    statGroupId: {
      type: Number, 
      required: true,
    }
  },

  data: () => ({
    loaded: false,

    orderStats: [],
    dragging: false,

    nameSearch: "",

    modal: false,
    modalHeaderText: "Create Stat",
    modalStat: null,
    creating: false,

    submitStatLoading: false,
    
    statGroup: null,
    stats: [],

    headers: [
      {
        text: "Name",
        align: "start",
        width: "25%",
        sortable: true,
        value: "name",
      },
      {
        text: "Abbreviation",
        align: "start",
        width: "25%",
        sortable: true,
        value: "abbreviation",
      },
      {
        text: "Mapping",
        align: "start",
        width: "25%",
        sortable: true,
        value: "mapping",
      },
      {
        text: "Actions",
        align: "center",
        width: "25%",
        sortable: false,
        value: "actions",
      },
    ]
  }),

  computed: {
    statsFiltered() {
      return this.stats
        .filter(at => this.nameSearch == null || at.name.toLocaleLowerCase().includes(this.nameSearch.toLocaleLowerCase()))
        .filter(at => this.selectedGlobalSportId == null || at.globalSportId == this.selectedGlobalSportId)
    }
  },

  methods: {
    openCreateModal() {
      this.modalStat = {
        id: -1,
        name: null,
        abbreviation: null,
        mapping: null,
        rank: 0,
        statGroupId: this.statGroupId,
      }

      this.creating = true
      this.modalHeaderText = "Create Stat"
      this.modal = true
    },

    openEditModal(stat) {
      this.modalStat = {
        id: stat.id,
        name: stat.name,
        abbreviation: stat.abbreviation,
        mapping: stat.mapping,
        rank: stat.rank,
        statGroupId: this.statGroupId,
      }

      this.creating = false
      this.modalHeaderText = "Edit Stat"
      this.modal = true
    },

    cancelModal() {
      this.modalStat = null
      this.modal = false
    },

    async submitStat() {
      if(this.submitStatLoading) return
      if(!this.$refs.form.validate()) return
      this.submitStatLoading = true

      if(this.creating) {
        this.modalStat.rank = this.stats.length
        const res = await Controllers.StatController.SupportCreateStat(this.modalStat)
        if(res && !res.hasError) {
          const statGroup = res.data
          this.stats.push(statGroup)
          this.orderStats = [...this.stats]
          this.$root.showSnackbar('Stat created successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to create stat.', "error", 5000)
        }
      }
      else {
        const res = await Controllers.StatController.SupportEditStat(this.modalStat)
        if(res && !res.hasError) {
          const stat = res.data
          const listStat = this.stats.find(at => at.id == stat.id)
          listStat.name = stat.name
          listStat.abbreviation = stat.abbreviation
          listStat.mapping = stat.mapping
          this.orderStats = [...this.stats]
          this.$root.showSnackbar('Stat edited successfully!', "success", 5000)
        }
        else {
          // Error handling
          this.$root.showSnackbar('Failed to edit stat.', "error", 5000)
        }
      }

      this.submitStatLoading = false
      this.cancelModal()
    },

    async deleteStat(stat) {
      const res = await Controllers.StatController.SupportDeleteStat(stat.id)
      if(res && !res.hasError) {
        this.stats = this.stats.filter(s => s.id != stat.id)
        this.orderStats = [...this.stats]
        this.$root.showSnackbar('Stat deleted successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to delete stat.', "error", 5000)
      }
    },

    async saveStatOrder() {
      this.orderStats.forEach((stat, index) => stat.rank = index)

      const res = await Controllers.StatController.SupportEditStats(this.orderStats)
      if(res && !res.hasError) {
        this.stats = [...this.orderStats]
        this.$root.showSnackbar('Stat order saved successfully!', "success", 5000)
      }
      else {
        this.$root.showSnackbar('Failed to save stat order.', "error", 5000)
      }
    },

  },

  async created() {
    this.$root.updateTitle("Stats")

    // Pre-fetch stat masks. (Since they aren't sport specific.)
    const res = await Controllers.StatGroupController.SupportGetStatGroup(this.statGroupId)
    if(res && !res.hasError) {
      const stats = res.data.stats

      let sport = GlobalSports.find(s => s.globalSportId == res.data.globalSportId)
      if(sport == null) sport = { globalSportId: -1, name: "Hidden" }

      this.statGroup = {
        id: res.data.id,
        globalSportId: res.data.globalSportId,
        name: res.data.name,
        sport: sport
      }
      
      this.stats = stats
      this.orderStats = [...stats]
    }
    else {
      // Error handling
    }

    this.loaded = true
  },
}
</script>

<style lang="scss" scoped>
.stat-abbrev:hover {
  cursor: grab;
}
</style>